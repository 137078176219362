@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: "Raleway";
  src: url(assets/raleway_regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url(assets/raleway_semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url(assets/raleway_bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.bg-main {
  background-image: url(./assets/background.svg);
  background-size: cover;
}

.backdrop-blur-2 {
  backdrop-filter: blur(0.5rem);
}

html {
  @apply font-raleway;
}

#gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.min-h-content {
  min-height: fit-content;
}
